import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './boost-your-coding-fu-with-vscode-and-vim.module.css'
import Layout from '../vscodevimbook/Layout'
import { BoostCodingFuPodcastSEO } from '../components/seo'
import { HeroText } from '../vscodevimbook/components/hero-text/hero-text'

export default class BoostCodingFuVSCodeVimPodcast extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const episodes = data.allMarkdownRemark.edges.map((e) => ({
      title: e.node.frontmatter.title,
      url: e.node.frontmatter.url,
      description: e.node.frontmatter.description,
      number: e.node.frontmatter.number,
    }))
    return (
      <Layout>
        <article className={styles.page}>
          <BoostCodingFuPodcastSEO
            title={`Boost Your Coding Fu With VSCode and Vim - The Book | ${siteTitle}`}
            description={`Unleash the power of Vim in Visual Studio Code, boost your productivity and become an even more awesome developer. This is the vocal experience and experiment, companion to the Boost Your Coding Fu Book.`}
          />
          <header className={styles.header}>
            <section className={styles.headerbody}>
              <HeroText />
              <article className={styles.podcastContent}>
                <img
                  width="1000"
                  height="1000"
                  style={{ maxWidth: '400px' }}
                  src="/images/boost-your-coding-fu-square-banner.jpg"
                  alt="Boost Your Coding Fu With VSCode and Vim - The Auditory Experience"
                  title="Boost Your Coding Fu With VSCode and Vim - The Auditory Experience"
                />
                <section>
                  <p>
                    Welcome to the Boost Your Coding Fu With VSCode and Vim
                    podcast!
                  </p>
                  <p>
                    Learn Vim while cleaning the dishes or doing the laundry. Or
                    fall asleep while listening to my silky soothing voice.
                  </p>
                  <p>
                    Enjoy this auditory experience where I personally narrate
                    Boost Your Coding Fu With VSCode and Vim for your pleasure.
                  </p>
                </section>
              </article>
            </section>
          </header>
          <section className={styles.content}>
            <section className={styles.podcast}>
              <h2 style={{ margin: 0 }}>Episodes</h2>
            </section>
          </section>
          <section className={styles.content}>
            <Episodes episodes={episodes} />
          </section>
        </article>
      </Layout>
    )
  }
}

const Episodes = ({ episodes }) => (
  <ul className={styles.episodes}>
    {episodes.map((episode) => (
      <li className={styles.episode} key={episode.number}>
        <section className={styles.episodeImage}>
          <img
            width="100%"
            className="left"
            src="/images/boost-your-coding-fu-square-banner.jpg"
            alt="Boost Your Coding Fu With VSCode and Vim - The Auditory Experience"
            title="Boost Your Coding Fu With VSCode and Vim - The Auditory Experience"
          />
        </section>
        <section className={styles.episodeDescription}>
          <h2>
            <span className={styles.episodeNumber}>{episode.number}.</span>{' '}
            {episode.title}
          </h2>
          <p>{episode.description}</p>
          <Audio url={episode.url} />
        </section>
      </li>
    ))}
  </ul>
)

const Audio = ({ url }) => (
  <section>
    <audio src={url} controls preload="none"></audio>
  </section>
)

export const pageQuery = graphql`
  query BoostCodingFuVSCodeVimPodcastQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        frontmatter: {
          draft: { eq: false }
          podcast: {
            eq: "Boost Your Coding Fu With VSCode and Vim, The Auditory Experience"
          }
        }
      }
    ) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date
            title
            categories
            description
            chapter
            number
            url
          }
          timeToRead
        }
      }
    }
  }
`
