// extracted by mini-css-extract-plugin
export var avatar = "boost-your-coding-fu-with-vscode-and-vim-module--avatar--df65c";
export var backgroundHighlight1 = "#FFEB3B";
export var backgroundHighlight2 = "#008fff";
export var backgroundHighlight3 = "#84d17e";
export var backgroundHighlight4 = "#84d17e";
export var book = "boost-your-coding-fu-with-vscode-and-vim-module--book--96ee6";
export var bookactions = "boost-your-coding-fu-with-vscode-and-vim-module--bookactions--550ef";
export var content = "boost-your-coding-fu-with-vscode-and-vim-module--content--17cb3";
export var episode = "boost-your-coding-fu-with-vscode-and-vim-module--episode--83687";
export var episodeDescription = "boost-your-coding-fu-with-vscode-and-vim-module--episode-description--98d64";
export var episodeImage = "boost-your-coding-fu-with-vscode-and-vim-module--episode-image--00fbc";
export var episodeNumber = "boost-your-coding-fu-with-vscode-and-vim-module--episode-number--531af";
export var episodes = "boost-your-coding-fu-with-vscode-and-vim-module--episodes--dc434";
export var exercise = "boost-your-coding-fu-with-vscode-and-vim-module--exercise--69722";
export var exerciseDescription = "boost-your-coding-fu-with-vscode-and-vim-module--exercise-description--74372";
export var exerciseImage = "boost-your-coding-fu-with-vscode-and-vim-module--exercise-image--119a5";
export var exerciseNumber = "boost-your-coding-fu-with-vscode-and-vim-module--exercise-number--cd2b4";
export var exercises = "boost-your-coding-fu-with-vscode-and-vim-module--exercises--24671";
export var header = "boost-your-coding-fu-with-vscode-and-vim-module--header--e98b5";
export var headerbody = "boost-your-coding-fu-with-vscode-and-vim-module--headerbody--4523b";
export var image = "boost-your-coding-fu-with-vscode-and-vim-module--image--6f0fe";
export var moreComingSoon = "boost-your-coding-fu-with-vscode-and-vim-module--more-coming-soon--156bd";
export var oldhighlight = "#2e9cff";
export var page = "boost-your-coding-fu-with-vscode-and-vim-module--page--0819b";
export var plugin = "boost-your-coding-fu-with-vscode-and-vim-module--plugin--50845";
export var podcast = "boost-your-coding-fu-with-vscode-and-vim-module--podcast--57670";
export var podcastContent = "boost-your-coding-fu-with-vscode-and-vim-module--podcast-content--881ea";
export var videoplayer = "boost-your-coding-fu-with-vscode-and-vim-module--videoplayer--08c60";
export var videos = "boost-your-coding-fu-with-vscode-and-vim-module--videos--9f38e";