import React from 'react'
import * as styles from './hero-text.module.css'

export const HeroText = () => (
  <p className={styles.heroText}>
    Unleash the power of Vim in Visual Studio Code, boost your productivity and{' '}
    <strong>become an awesome developer</strong>. Learn to code at the speed of
    thought.
  </p>
)

